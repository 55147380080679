/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
			window.addEventListener('load', function () {

				// Get reference to iframe contentWindow

				var f = document.getElementById("pluginFrame").contentWindow;

				// configure data for plug-in.
				// type:        message type should always be 'ekyros'
				// apiKey:      Identifies Call Center
				// regKeys:     array that identifies 1 or more office locations for a pregnancy center.
				// themeColor:  specify a primary color used by the plug-in.  This is (optional) param, leave blank for default 'blue' color. 
				//              accepts hex value or named color.
				// waitTimeInMinutes: (optional) specifies duration (in minutes) before the user can create another appointment.  Default is 30 minutes.

				var data = {
					"type": 'ekyros',
					"apiKey": '1c2afaa0-4cd3-4f76-9462-dbd00417442d',
					"regKeys": ["540bf8f6-7845-4d61-9e9f-c6c61b7c500f"],
					"themeColor": '#917f6b',
					"waitTimeInMinutes": 30
				};

				// post message to plug-in running in iframe with config data.
				// Param1: Config data for Plug-in
				// Param2: Secuity requirement.  Must be origin (domain) used in iframe SRC attribute.

				f.postMessage(data, "https://pluslinkplugin.ekyros.com");
			});
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
